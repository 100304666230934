<template>
<div class="myteam animate__animated animate__fadeInDown">
    
    <div class="header" >
        <a-icon class="back" @click="$router.go(-1)" type="left" />
        <span class="title">{{$t('profile.titles.team')}}</span>
    </div>
    <a-row type="flex" class="top" :gutter="16">
        <a-col :span="12">
            {{$t('team.totalInvited')}}: {{$store.state.userinfo.total_team}}
        </a-col>
        <a-col :span="12">
            <a-input class="search-ipt" allowClear v-model="skey" @change="onChange" :placeholder="$t('team.searchPlaceholder')">
                <a-icon slot="prefix" type="search" />
            </a-input>
        </a-col>
    </a-row>
    
    <!-- <div v-for="s,i in data" :key="'s'+i" class="box">
        <div class="up flex" @click="goInvites(s.phone)">
            <div class="address">
                <div class="title bd primary"><span>{{s.phone}}</span></div>
                <div class="label"><span>{{s.created_at}}</span></div>
            </div>
            <div>
                <span class="bd">{{s.sons.length}}</span>
                <a-icon class="arrow" type="caret-right"  />
            </div>
        </div>
    </div> -->
    <div class="team-tree">
    <a-tree show-line  >
        <a-icon slot="switcherIcon" type="down" />
        <a-tree-node v-for="u,i in data" :key="'u'+i" :title="u.phone">
            <a-tree-node v-for="su,ii in u.sons_children" :key="'su'+ii" :title="su.phone">
                <a-tree-node v-for="ssu,iii in su.sons_children" :key="'ssu'+iii" :title="ssu.phone">
                    <a-tree-node v-for="sssu,iiii in ssu.sons_children" :key="'sssu'+iiii" :title="sssu.phone" />
                </a-tree-node>
            </a-tree-node>
        </a-tree-node>
    </a-tree>
    </div>



    <div v-if="showLoadingMore"
        :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }"
    >
        <a-spin v-if="loading" />
        <a-button v-else type="dashed" ghost @click="getData(true)">
            {{$t('common.more')}}
        </a-button>
    </div>

</div>
  
</template>
<script>

export default {
  name: "Team",
  data() {
    return {
      loading: false,
      showLoadingMore: false,
      page:1,
      pageSize: 5,
      asc: 0,
      data: [],
      skey:'',
      paddress: this.$route.params.paddress,

    };
  },
  mounted() {

    this.getData(false);
  },
  watch: {
    // checkedKeys(val) {
    //   console.log('onCheck', val);
    // },
  },
  methods: {
    // onExpand(expandedKeys) {
    //   console.log('onExpand', expandedKeys);
    //   // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    //   // or, you can remove all expanded children keys.
    //   this.expandedKeys = expandedKeys;
    //   this.autoExpandParent = false;
    // },
    // onCheck(checkedKeys) {
    //   console.log('onCheck', checkedKeys);
    //   this.checkedKeys = checkedKeys;
    // },
    // onSelect(selectedKeys, info) {
    //   console.log('onSelect', info);
    //   this.selectedKeys = selectedKeys;
    // },

    getData(append = true){
        this.loading = true;
        this.$http.call(this.$http.api.teams,{
            data:{page:this.page,size:this.pageSize,sort:this.asc,skey:this.skey,paddress:this.paddress}
        }).then(res=>{
            // console.log(res);
            this.loading = false;
            let resp = res.data;
            if(resp.code=='200'){
                this.page++;
                if(append){
                    this.data = this.data.concat(resp.data);
                }else{
                    this.data = resp.data;
                }
                if(resp.data.length<this.pageSize){
                    this.showLoadingMore = false;
                }
            }else{
                this.$message.error(resp.data);
            } 
            
        },res=>{
            console.log('something goes wrong...');
            console.log(res.response);
            if(res.response.status == '401'){
                this.$store.commit('setToken','');
                this.$router.push({name:'login'});
            }
        }).then(()=>{
            
        });
    },
    onSearch(value){
        console.log(value,this.skey)
        this.skey = value;
        this.page = 1;
        this.getData(false);
    },
    onChange(){
        // console.log(value,this.skey)
        // this.skey = value;
        this.page = 1;
        this.getData(false);
    },
    changeAsc(){
        this.asc = !this.asc;
        this.page = 1;
        this.getData(false);
    },
    // goRecords(address){
    //     this.$router.push({name: 'records', params:{address: address}})
    // },
    // goInvites(phone){
    //     this.$router.push({name: 'team', params:{paddress: phone}})

    // }
  },
};
</script>
<style lang="less" scoped>
.myteam{
    padding: 10px;
    width: 100%;
    background:#fff;
    color: #000;
    .header{
        position: relative;
        // color: #fff;
        // font-size: 16px;
        display:flex;
        align-items: center;
        padding-bottom: 30px;
        .back{
            position: absolute;
            left: 0;
            // color:#fff;
        }
        .title{
            // font-size: 10px;
            flex: 1;
        }
    }
    .top{
        display: flex;
        align-items: center;
        .search-ipt{
            .ant-input{
                padding: 13px 20px 13px 38px;
                height: auto;
                border: 0;
            }
            .ant-input-suffix{
                color: #888;
            }
            .ant-input-search-icon{
                color: #888;
            }
            .ant-input-clear-icon{
                color: #888;
            }
        }
    }
    .team-tree{
        text-align: left;
        background: #fff;
    }
    .box {
        padding: 20px;
        margin: 20px 0;
        font-size: 14px;
        position:relative;
        overflow: hidden;
        
        .up{
            padding-bottom: 15px;
            .address{
                text-align: left;
                .title{
                    font-size: 16px;
                }
            }
        }
        
        .label{
            font-size: 12px;
        }
        .bd{
            font-weight: 700;
        }
        .primary{
            color: @primary-color;
        }
    }.myTotal{
        color: @primary-color;
        padding: 20px;
        // justify-content: space-between;
    }
}
</style>
